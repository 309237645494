import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { Menu } from "@headlessui/react";
import { Avatar } from "@unlikelyai-magic/ui/avatars";
import { colors } from "@unlikelyai-magic/ui/variables";
import { Paragraph } from "@jobe/ui/typography";
import { useProfile } from "../hooks";

const StyledMenu = styled(Menu)`
  position: relative;
`;

const ProfileAvatar = styled(Avatar)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.brand.primary};
`;

const Panel = styled(Menu.Items)`
  position: absolute;
  right: 0;
  z-index: 50;
  margin-top: 0.375rem;
  width: 12rem;
  border-radius: 0.375rem;
  background-color: white;
  padding: 0.25rem 0;
  box-shadow:
    0 0.625rem 0.9375rem -0.1875rem rgb(0 0 0 / 0.1),
    0 0.25rem 0.375rem -0.25rem rgb(0 0 0 / 0.1);
  border: 1px solid ${colors.gray["200"]};

  &:focus {
    outline: none;
  }
`;

const MenuItemLink = styled(NavLink)<{ active?: boolean }>`
  display: block;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: ${({ active }) => (active ? colors.gray["100"] : "")};

  &,
  &:hover,
  &:focus {
    color: ${colors.gray["900"]};
  }
`;

const dropdownOptions = [{ name: "Sign out", href: "/logout" }];

export const ProfileDropdownMenu = () => {
  const profile = useProfile();

  if (!profile) {
    return null;
  }

  return (
    <StyledMenu as="div">
      <Menu.Button as={Fragment}>
        <ProfileAvatar
          src={profile.profileImageUrl}
          size="sm"
          alt={
            profile.firstName.slice(0, 1).toUpperCase() +
            profile.lastName.slice(0, 1).toUpperCase()
          }
        />
      </Menu.Button>
      <Panel>
        {dropdownOptions.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <MenuItemLink to={item.href} active={active}>
                <Paragraph>{item.name}</Paragraph>
              </MenuItemLink>
            )}
          </Menu.Item>
        ))}
      </Panel>
    </StyledMenu>
  );
};
