import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { colors } from "@unlikelyai-magic/ui/variables";

export type CardContainerProps = ComponentProps<"div">;

export const CardContainer = styled((props: CardContainerProps) => {
  return <div {...props} />;
})`
  background-color: ${colors.white};
  border-radius: 0.75rem;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
`;
