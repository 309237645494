import { useState } from "react";
import styled from "@emotion/styled";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { TransparentButton, WhiteButton } from "@jobe/ui/buttons";
import { useProfile } from "@jobe/ui/features/authentication";
import {
  ChatContainer,
  ChatMessage,
  ChatMessages,
  ChatTyping,
  MessageAuthor,
  MessageType,
} from "@jobe/ui/features/chat";
import {
  CreateJobOpeningChat,
  JobPostingsGrid,
  useListJobPostingsQuery,
} from "@jobe/ui/features/jobPostings";
import { Paragraph } from "@jobe/ui/typography";
import { OnboardingChatProvider } from "../providers";

const GreetingContainer = styled(ChatContainer)`
  margin: 0 auto;

  @media (min-width: ${screenBreakpoints.lg}) {
    max-width: ${screenBreakpoints.lg};
  }
`;

const ChevronIcon = styled.div`
  margin-top: 0.125rem;
`;

const ExtendedJobOpeningsGridContainer = styled.div`
  // this prevents the animations from lumping as the 'show all' button renders/exits
  margin-top: -1.5rem;
`;

const ButtonContainer = styled.div`
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const GreetChatMessage = () => {
  const profile = useProfile();
  const hour = new Date().getHours();
  const [creating, setCreating] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { data, isLoading, refetch } = useListJobPostingsQuery(
    {
      offset: 0,
      limit: 0,
      includeLikedCount: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const formatGreeting = () => {
    const prefix =
      hour < 12
        ? "Good morning"
        : hour < 18
          ? "Good afternoon"
          : "Good evening";

    return `${prefix}${profile ? ", " + profile.firstName : ""}! You have ${
      data?.paging.totalCount
    } active job opening${data?.paging.totalCount === 1 ? "" : "s"}:`;
  };

  const toggleCreateJobOpening = () => {
    setCreating((prev) => !prev);
  };

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  if (isLoading || !data) {
    return <ChatTyping />;
  }

  const firstJobOpeningsBatch = data.data.slice(0, 9);
  const otherJobOpenings = data.data.slice(9);
  const numberOfExtraRows = Math.ceil(otherJobOpenings.length / 4);

  return (
    <Spacing>
      <AnimatePresence>
        {!creating && (
          <motion.div
            initial={{ translateY: -100, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            exit={{ translateY: -100, opacity: 0 }}
            transition={{ ease: "easeInOut" }}
          >
            {data.paging.totalCount > 0 ? (
              <GreetingContainer>
                <TransparentButton
                  icon={<PlusIcon />}
                  onClick={toggleCreateJobOpening}
                >
                  <Paragraph small>Job opening</Paragraph>
                </TransparentButton>
                <ChatMessage
                  message={{
                    id: uuidv4(),
                    sentAt: new Date().toISOString(),
                    author: MessageAuthor.Bot,
                    type: MessageType.Text,
                    text: formatGreeting(),
                    widget: (
                      <Spacing gap="3xs">
                        <motion.div
                          initial={{ opacity: 0, translateY: 24 }}
                          animate={{
                            opacity: 1,
                            translateY: 0,
                            transition: { delay: 0.2 },
                          }}
                        >
                          <JobPostingsGrid
                            jobPostings={firstJobOpeningsBatch}
                            onDelete={refetch}
                          />
                          <ButtonContainer>
                            <AnimatePresence>
                              {otherJobOpenings.length > 0 && !showAll && (
                                <motion.div
                                  key="showAllButton"
                                  initial={{
                                    opacity: 0,
                                    translateY: 100 * numberOfExtraRows,
                                  }}
                                  animate={{
                                    opacity: 1,
                                    translateY: 0,
                                    transition: { delay: 0.2 },
                                  }}
                                  exit={{
                                    opacity: 0,
                                    translateY: 100 * numberOfExtraRows,
                                  }}
                                  transition={{ ease: "easeInOut" }}
                                >
                                  <TransparentButton onClick={toggleShowAll}>
                                    <Spacing gap="xs" direction="horizontal">
                                      <Paragraph small>Show All</Paragraph>
                                      <ChevronIcon as={ChevronDownIcon} />
                                    </Spacing>
                                  </TransparentButton>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </ButtonContainer>
                          <ExtendedJobOpeningsGridContainer>
                            <AnimatePresence>
                              {showAll && (
                                <motion.div
                                  key="jobPostingsGrid"
                                  initial={{ opacity: 0, translateY: -50 }}
                                  animate={{ opacity: 1, translateY: 0 }}
                                  exit={{ opacity: 0, translateY: -50 }}
                                  transition={{ ease: "easeInOut" }}
                                >
                                  <JobPostingsGrid
                                    jobPostings={otherJobOpenings}
                                    onDelete={refetch}
                                  />
                                </motion.div>
                              )}
                            </AnimatePresence>
                            <AnimatePresence>
                              {showAll && (
                                <motion.div
                                  key="showLessButton"
                                  initial={{
                                    opacity: 0,
                                    translateY: -100 * numberOfExtraRows,
                                  }}
                                  animate={{
                                    opacity: 1,
                                    translateY: 0,
                                    transition: { delay: 0.2 },
                                  }}
                                  exit={{
                                    opacity: 0,
                                    translateY: -100 * numberOfExtraRows,
                                  }}
                                  transition={{ ease: "easeInOut" }}
                                >
                                  <ButtonContainer>
                                    <TransparentButton onClick={toggleShowAll}>
                                      <Spacing gap="xs" direction="horizontal">
                                        <Paragraph small>Show Less</Paragraph>
                                        <ChevronIcon as={ChevronUpIcon} />
                                      </Spacing>
                                    </TransparentButton>
                                  </ButtonContainer>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </ExtendedJobOpeningsGridContainer>
                        </motion.div>
                      </Spacing>
                    ),
                  }}
                />
              </GreetingContainer>
            ) : (
              <Spacing items="center">
                <OnboardingChatProvider onSubmit={toggleCreateJobOpening}>
                  <ChatMessages />
                </OnboardingChatProvider>
              </Spacing>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {creating && (
          <motion.div
            initial={{ translateY: 100, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1, transition: { delay: 0.4 } }}
            exit={{ translateY: 100, opacity: 0 }}
            transition={{ ease: "easeInOut" }}
          >
            <Spacing gap="xl">
              <Spacing items="center">
                <WhiteButton
                  icon={<ChevronUpIcon />}
                  onClick={toggleCreateJobOpening}
                >
                  My active openings
                </WhiteButton>
              </Spacing>
              <Spacing items="center">
                <CreateJobOpeningChat />
              </Spacing>
            </Spacing>
          </motion.div>
        )}
      </AnimatePresence>
    </Spacing>
  );
};
