import { ComponentProps, ReactNode } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { colors } from "@unlikelyai-magic/ui/variables";
import { SlideOverContent } from "./SlideOverContent";
import { SlideOverHeader } from "./SlideOverHeader";

const Fixed = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  max-width: 31.25rem;
  right: 0;
  top: 0;
`;

const PanelContainer = styled.div`
  height: 100%;
`;

const Panel = styled(Dialog.Panel)`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${colors.white};
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
`;

export type SlideOverProps = ComponentProps<"div"> & {
  header?: ReactNode;
};

const SlideOverRoot = ({ header, children, className }: SlideOverProps) => {
  return (
    <Fixed>
      <PanelContainer>
        <Panel className={className}>{children}</Panel>
      </PanelContainer>
    </Fixed>
  );
};

export const SlideOver = Object.assign(SlideOverRoot, {
  Header: SlideOverHeader,
  Content: SlideOverContent,
});
