import React, {
  Fragment,
  KeyboardEvent,
  ReactElement,
  forwardRef,
  useMemo,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Combobox } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Placement } from "@popperjs/core/lib/enums";
import { InputProps } from "antd";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { useDebounce } from "@unlikelyai-magic/ui/debounce";
import { colors, fontSizes } from "@unlikelyai-magic/ui/variables";
import {
  JobSpecificationRequirement,
  Requirement,
} from "@jobe/data-access/api-types";
import { PlumButton } from "@jobe/ui/buttons";
import {
  useFindRequirementByNaturalLanguageOrCreateMutation,
  useGetRequirementsSearchQuery,
} from "@jobe/ui/features/shared";
import { Input } from "@jobe/ui/inputs";
import { Paragraph } from "@jobe/ui/typography";

const RequirementInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  font-size: 1.5rem;
  z-index: 1;
  pointer-events: none;
`;

const AddonIcon = styled.svg`
  width: 1em;
  height: 1em;
`;

const StyledInput = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forwardRef<HTMLInputElement, InputProps>((props, ref) => <Input {...props} />)
)`
  font-size: ${fontSizes.md};
  line-height: ${fontSizes.xxl};
  font-weight: 600;
  padding: 1rem 1rem 1rem 3rem;
  background: none;
  border-radius: 7.5rem;

  &,
  &:hover,
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

const Container = styled.div<{ inputFocus: boolean }>`
  position: relative;
  border-radius: 7.5rem;
  background: white;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  border: 1px solid ${colors.gray["200"]};

  ${({ inputFocus, theme }) =>
    inputFocus &&
    css`
      border: 1px solid ${theme.colors.mili.tangerine};
      box-shadow: 0 0 0 2px ${theme.colors.mili.tangerine}20;
    `}
`;

const Options = styled(Combobox.Options)`
  width: 100%;
  overflow: hidden;
  z-index: 10;
  background: white;
  border-radius: 0.625rem;
  border: 1px solid ${colors.gray["200"]};
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  list-style: none;
  padding: 0;
  max-height: 16rem;
  overflow-y: auto;
`;

const Option = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: ${colors.gray["200"]};
  }
`;

const EmbeddedButtonContainer = styled.div`
  margin-right: 0.4375rem;
`;

export type RequirementInputProps = {
  autoFocus?: boolean;
  value: JobSpecificationRequirement[];
  onCreate: (value: JobSpecificationRequirement) => void;
  placement?: Placement;
  submitButton?: ReactElement;
  className?: string;
};

export const RequirementInput = styled(
  ({
    autoFocus,
    value,
    onCreate,
    placement = "auto",
    submitButton,
    className,
  }: RequirementInputProps) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      strategy: "absolute",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
    const [query, setQuery] = useState("");
    const [mouseOverCombobox, setMouseOverCombobox] = useState(false);
    const [currentRequirement, setCurrentRequirement] =
      useState<JobSpecificationRequirement>();
    const searchQuery = useDebounce(query, 200);
    const { data, isLoading } = useGetRequirementsSearchQuery({
      query: searchQuery,
    });
    const options = useMemo(() => {
      if (!data) return [];
      return data.data.filter((prediction: Requirement) => {
        return !value.find(
          (requirement: Requirement) => requirement.id === prediction.id
        );
      });
    }, [data, value]);
    const [findRequirementByIdOrCreate, { isLoading: isCreatingRequirement }] =
      useFindRequirementByNaturalLanguageOrCreateMutation();
    const { track } = useMetrics();

    const handleChange = async (prediction: Requirement) => {
      // if there is no selection or the mouse is not currently hovering over the input or dropdown, just handle enter for what is currently in the input
      if (!prediction?.id || !mouseOverCombobox) {
        await handleEnter(false);
        return;
      }
      const requirement: JobSpecificationRequirement = {
        id: prediction.id,
        text: prediction.text,
        mustHave: true,
      };
      track("JobSpecSetRequirements", "requirement_added", {
        wasSuggested: true,
        ...requirement,
      });
      setQuery("");
      setCurrentRequirement(requirement);
      onCreate(requirement);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      event.preventDefault();
    };

    const handleKeyUp = async (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      event.preventDefault();

      await handleEnter(true);
    };

    /**
     * Returns a requirement for the current query
     */
    const requirementFromQuery =
      async (): Promise<JobSpecificationRequirement> => {
        const result = (
          await findRequirementByIdOrCreate({
            natLanguageRequirement: query,
          }).unwrap()
        ).data;

        return {
          id: result.id,
          text: result.text,
          mustHave: true,
        };
      };

    const handleEnter = async (triggeredByEnterKey: boolean) => {
      // if there is nothing in the input, we don't want to do anything
      if (!query) {
        return;
      }
      // if the mouse it outside the combobox, we only want to do something if they user submitted the input by pressing enter
      if (!mouseOverCombobox && !triggeredByEnterKey) {
        return;
      }
      // check whether the input has already been added as a requirement, if so clear the input and do nothing more
      const currentRequirements = value.map((requirement) => requirement.text);
      if (currentRequirements.includes(query)) {
        setQuery("");
        return;
      }

      // If the user inputs something that we didn't suggest, we need to create a requirement for it
      const requirementToSubmit =
        currentRequirement || (await requirementFromQuery());
      track("JobSpecSetRequirements", "requirement_added", {
        wasSuggested: false,
        ...requirementToSubmit,
      });
      setQuery("");
      setCurrentRequirement(undefined);
      onCreate(requirementToSubmit);
    };

    const renderOptions = () => {
      if (query) {
        return (
          <Options
            ref={setPopperElement}
            style={styles.popper}
            onMouseEnter={() => setMouseOverCombobox(true)}
            onMouseLeave={() => setMouseOverCombobox(false)}
            {...attributes.popper}
          >
            <Combobox.Option as={Fragment} value={{ id: "", value: query }}>
              <Option>
                <Paragraph small>Add "{query}"</Paragraph>
              </Option>
            </Combobox.Option>
            {options.map((prediction: Requirement) => (
              <Combobox.Option
                as={Fragment}
                key={prediction.id}
                value={prediction}
              >
                <Option>
                  <Paragraph small>{prediction.text}</Paragraph>
                </Option>
              </Combobox.Option>
            ))}
          </Options>
        );
      }
      if (options.length > 0) {
        return (
          <Options
            ref={setPopperElement}
            style={styles.popper}
            onMouseEnter={() => setMouseOverCombobox(true)}
            onMouseLeave={() => setMouseOverCombobox(false)}
            {...attributes.popper}
          >
            {options.map((prediction: Requirement) => (
              <Combobox.Option
                as={Fragment}
                key={prediction.id}
                value={prediction}
              >
                <Option>
                  <Paragraph small>{prediction.text}</Paragraph>
                </Option>
              </Combobox.Option>
            ))}
          </Options>
        );
      }
      return null;
    };

    return (
      <Combobox
        as={RequirementInputContainer}
        value={undefined}
        onChange={handleChange}
      >
        <Combobox.Button
          as={Container}
          ref={setReferenceElement}
          inputFocus={mouseOverCombobox}
        >
          <IconContainer>
            <AddonIcon as={PlusIcon} />
          </IconContainer>
          <Combobox.Input
            as={StyledInput}
            placeholder="Add a job requirement"
            value={query}
            onKeyUp={!isLoading ? handleKeyUp : undefined}
            onKeyDown={handleKeyDown}
            onChange={(event) => {
              setQuery(event.target.value);
              setCurrentRequirement(undefined);
            }}
            autoFocus={autoFocus}
            autoComplete="off"
            onMouseEnter={() => setMouseOverCombobox(true)}
            onMouseLeave={() => setMouseOverCombobox(false)}
            className={className}
          />
          {!query && !isCreatingRequirement && submitButton ? (
            <EmbeddedButtonContainer>{submitButton}</EmbeddedButtonContainer>
          ) : null}
          {(query || isCreatingRequirement) && (
            <EmbeddedButtonContainer>
              <PlumButton
                loading={isCreatingRequirement}
                onClick={() => handleEnter(true)}
              >
                Add requirement
              </PlumButton>
            </EmbeddedButtonContainer>
          )}
          {renderOptions()}
        </Combobox.Button>
      </Combobox>
    );
  }
)``;
